import { GoogleSpreadsheetRow } from "google-spreadsheet";
import {
  ChangeEvent,
  ChangeEventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import * as Ons from "react-onsenui";
import {
  compressImage,
  fullyWriteStepComplete,
  getImage,
  getStep,
  writeImage,
} from "../../sheets";
import PullToRefresh from "react-simple-pull-to-refresh";

const loadStep = async (
  stepKey: string,
  setStep: (row: GoogleSpreadsheetRow | null) => unknown
) => {
  const step = await getStep(stepKey);
  setStep(step);
  return step;
};

const loadImageUrl = async (
  step_image_index: number,
  setImageUrl: (row: string | undefined | null) => unknown
) => {
  const imageData = await getImage(step_image_index);
  console.log("IMAGE URL LOADED", imageData?.length);
  setImageUrl(imageData);
};

const writeComplete = async (
  stepIndex: number,
  navigator: Ons.Navigator,
  setCompleting: (value: boolean) => unknown
) => {
  setCompleting(true);
  fullyWriteStepComplete(stepIndex, navigator, setCompleting);
};

const StepImpl = ({
  navigator,
  stepKey,
  stepIndex,
  title,
}: {
  navigator: Ons.Navigator;
  stepKey: string;
  stepIndex: number;
  title: string;
}) => {
  const [step, setStep] = useState<GoogleSpreadsheetRow | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [completing, setCompleting] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null | undefined>(
    undefined
  );

  useEffect(() => {
    loadStep(stepKey, setStep);
  }, [stepKey, setStep]);

  useEffect(() => {
    console.log("STEP IMAGE INDEX", step?.step_image_index);
    if (step == null || step.step_image_index == null) {
      return;
    }
    console.log("STEP IMAGE INDEX", step.step_image_index);
    if (imageUrl === undefined) {
      console.log("STEP IMAGE INDEX", step.step_image_index);
      loadImageUrl(step.step_image_index, setImageUrl);
    }
  }, [step, setImageUrl]);

  const onRefresh = useCallback(async () => {
    const newStep = await loadStep(stepKey, setStep);
    if (newStep != null) {
      await loadImageUrl(newStep.step_image_index, setImageUrl);
    }
  }, [stepKey, setStep, setImageUrl]);

  const onImage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUploading(true);
      const team = window.localStorage.getItem("team");
      if (!team) {
        return;
      }
      compressImage(event, team, stepIndex, (imageData: string) => {
        console.log("IMAGE URL", imageData?.length);
        setImageUrl(imageData);
        setUploading(false);
      });
    },
    [setUploading, stepIndex]
  );
  const onComplete = useCallback(() => {
    console.log("ON COMPLETE", step, step?.rowIndex);
    if (step != null) {
      writeComplete(step.rowIndex, navigator, setCompleting);
    }
  }, [step, setCompleting]);

  console.log("step data", step);
  return (
    <Ons.Page>
      <Ons.Toolbar inline>
        <div className="left">
          <Ons.BackButton>Back</Ons.BackButton>
        </div>
        <div
          className="center"
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          Step Details
        </div>
        <div className="right"></div>
      </Ons.Toolbar>
      <PullToRefresh
        onRefresh={onRefresh}
        isPullable={step != null}
        refreshingContent={
          <Ons.ProgressCircular style={{ marginTop: 12 }} indeterminate />
        }
      >
        <>
          {step != null ? (
            <Ons.ListItem
              style={{
                backgroundColor: "var(--list-background-color)",
              }}
            >
              <div
                style={{
                  marginTop: 6,
                  marginBottom: 24,
                }}
              >
                <h1 style={{ marginTop: 0, lineHeight: "32px", paddingTop: 0 }}>
                  {(step as unknown as { step_name: string }).step_name}
                </h1>
                {
                  (step as unknown as { step_description: string })
                    .step_description
                }
              </div>
            </Ons.ListItem>
          ) : (
            <div
              style={{
                width: "100%",
                marginTop: 25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Ons.ProgressCircular indeterminate />
            </div>
          )}
          {imageUrl != null ? (
            <div
              style={{
                backgroundColor: "var(--list-background-color)",
                display: "flex",
                width: "100%",
                paddingTop: 12,
                paddingBottom: 12,
                justifyContent: "center",
              }}
            >
              <img
                style={{ maxWidth: "95vw" }}
                src={`data:image/png;base64,${imageUrl}`}
              />
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "var(--list-background-color)",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 32,
              paddingBottom: 8,
            }}
          >
            {uploading ? (
              <Ons.ProgressCircular indeterminate />
            ) : step != null ? (
              <>
                <label htmlFor="id-upload">
                  <Ons.Button modifier="cta" icon="cloud-upload">
                    {" "}
                    Upload Image{" "}
                  </Ons.Button>
                </label>
                <input
                  id="id-upload"
                  style={{ display: "none" }}
                  disabled={uploading}
                  type="file"
                  accept="image/*"
                  onChange={onImage}
                />
              </>
            ) : null}
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "var(--list-background-color)",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 6,
              paddingBottom: 24,
            }}
          >
            {" "}
            {step != null ? (
              completing ? (
                <Ons.ProgressCircular indeterminate />
              ) : (
                <Ons.Button
                  disabled={!imageUrl}
                  icon="check"
                  onClick={onComplete}
                >
                  {" "}
                  Complete Step{" "}
                </Ons.Button>
              )
            ) : null}
          </div>
        </>
      </PullToRefresh>
    </Ons.Page>
  );
};

export const Step = memo(StepImpl);
