import { useRef, memo, useEffect, useState, useCallback } from "react";
import * as Ons from "react-onsenui";
import { GoogleSpreadsheetRow } from "google-spreadsheet";
import { getSteps } from "../../sheets";
import PullToRefresh from "react-simple-pull-to-refresh";

const ListingImpl = ({
  navigator,
  title,
  refresh,
}: {
  navigator: Ons.Navigator;
  title: string;
  refresh?: number;
}) => {
  const [rows, setRows] = useState<GoogleSpreadsheetRow[] | null>(null);

  console.log(
    "TOP PAGE",
    (
      document.querySelector("ons-navigator") as unknown as {
        topPage: { data: unknown };
      }
    ).topPage?.data
  );
  useEffect(() => {
    console.log("USE EFFECT", navigator);

    setTimeout(async () => {
      console.log("FETCH ROWS");
      const stepRows = await getSteps();
      setRows(stepRows);
    }, 0);
    return () => console.log("DISMOUNT <---------=======");
  }, [refresh]);

  const onRefresh = useCallback(async () => {
    console.log("REFETCH ROWS");
    const stepRows = await getSteps();
    setRows(stepRows);
  }, [setRows]);

  return (
    <Ons.Page>
      <Ons.Toolbar inline>
        <div className="left">
          <div className="left">
            <Ons.BackButton>Back</Ons.BackButton>
          </div>
        </div>
        <div className="center">{title}</div>
        <div className="right"></div>
      </Ons.Toolbar>
      {rows != null ? (
        <PullToRefresh
          onRefresh={onRefresh}
          refreshingContent={
            <Ons.ProgressCircular style={{ marginTop: 12 }} indeterminate />
          }
        >
          <Ons.List
            dataSource={rows}
            renderRow={(
              row: {
                step_emoji: string;
                step_name: string;
                step_id: string;
                step_description: string;
                step_status: string;
              },
              idx: number
            ) => (
              <Ons.ListItem
                tappable
                onClick={() =>
                  navigator.pushPage(
                    {
                      key: "STEP",
                      stepKey: row.step_id,
                      stepIndex: idx,
                      title: row.step_name,
                    },
                    { animation: "slide" }
                  )
                }
                disabled={
                  !(
                    idx === 0 ||
                    row.step_status === "DONE" ||
                    rows[idx - 1]?.step_status === "DONE"
                  )
                }
                modifier={
                  idx === (rows?.length ?? 0) - 1
                    ? "longdivider chevron"
                    : "chevron"
                }
              >
                <span style={{ marginRight: 10 }}>
                  {row.step_status !== "DONE" &&
                  (idx === 0 || rows[idx - 1]?.step_status === "DONE")
                    ? "⏩"
                    : row.step_status === "DONE"
                    ? "✔️"
                    : "🔒"}
                </span>
                <span style={{ marginRight: 10 }}>{row.step_emoji}</span>
                {idx === 0 ||
                row.step_status === "DONE" ||
                rows[idx - 1]?.step_status === "DONE"
                  ? row.step_name
                  : "Locked"}
              </Ons.ListItem>
            )}
          />
        </PullToRefresh>
      ) : (
        <div
          style={{
            width: "100%",
            marginTop: 25,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Ons.ProgressCircular indeterminate />
        </div>
      )}
    </Ons.Page>
  );
};

export const Listing = memo(ListingImpl);
