import { GoogleSpreadsheetRow } from "google-spreadsheet";
import type Cryptr from "cryptr";
import { memo, useCallback, useEffect, useLayoutEffect, useState } from "react";
import * as Ons from "react-onsenui";
import { secrets } from "../../sheets";

const LoginImpl = ({
  navigator,
  title,
}: {
  navigator: Ons.Navigator;
  title: string;
}) => {
  const [err, setErr] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = useCallback(
    (event: { target: { value: string | null } }) =>
      setPass(!event.target.value ? "" : event.target.value),
    [setPass]
  );

  const checkPass = useCallback(() => {
    if (pass.length === 0) {
      setErr("Password cannot be empty.");
      return;
    }
    setLoading(true);
    setTimeout(() => {
      console.log("VALUE: pass)", pass);
      const cryptr: Cryptr = new (
        window as unknown as { Cryptr: typeof Cryptr }
      ).Cryptr(pass);
      try {
        const stringResult = cryptr.decrypt(secrets);
        console.log("HERERERE?: ", stringResult);
        window.localStorage.setItem("pass", pass);
        navigator.pushPage({
          key: "RULES",
          title: "Rules & Team",
        });
      } catch (e) {
        console.log("FAILED");
        setLoading(false);
      }
    }, 40);
  }, [pass, setLoading, navigator]);

  console.log("LOADING", loading);
  return (
    <Ons.Page>
      <div
        style={{
          width: "100%",
          marginTop: 25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img style={{ marginBottom: 40, maxWidth: "100vw" }} src="/logo.gif" />
        {loading ? (
          <Ons.ProgressCircular indeterminate />
        ) : (
          <div>
            {err ? <p style={{ color: "#f00" }}>{err}</p> : null}
            <Ons.Input
              value={pass}
              onChange={onChange}
              placeholder="Enter Password"
              modifier="underbar"
              type="password"
            ></Ons.Input>
          </div>
        )}
        <div style={{ marginTop: 40 }}>
          <Ons.Button disabled={loading} onClick={checkPass}>
            Log In
          </Ons.Button>
        </div>
      </div>
    </Ons.Page>
  );
};

export const Login = memo(LoginImpl);
