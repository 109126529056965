import React, { useCallback, useState } from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import "./App.css";
import "onsenui/css/onsenui.css";
import "./onsen-css-components.css";
import * as Ons from "react-onsenui";
import { Listing } from "./pages/listing";
import { Login } from "./pages/login";
import { Step } from "./pages/step";
import { Rules } from "./pages/rules";

type RouteKey =
  | {
      key: "LOGIN";
      title: string;
    }
  | {
      key: "RULES";
      title: string;
    }
  | {
      key: "LISTING";
      title: string;
      refresh?: number;
    }
  | {
      key: "STEP";
      stepKey: string; // TODO: should be using data property of navigator pushPage
      stepIndex: number; // TODO: should be using data property of navigator pushPage
      title: string;
    };

const initialRouteStack = () => {
  const pass = localStorage.getItem("pass");
  if (pass != null) {
    const team = localStorage.getItem("team");
    if (team != null) {
      return [
        {
          key: "RULES",
          title: "Rules & Team",
        },
        {
          key: "LISTING",
          title: "Steps",
        },
      ];
    }
    return [
      {
        key: "RULES",
        title: "Rules & Team",
      },
    ];
  }
  return [
    {
      key: "LOGIN",
      title: "Login",
    },
  ];
};
function App() {
  const [refreshKey, setRefreshKey] = useState(0);

  const renderPage = useCallback(
    (route: RouteKey, navigator: Ons.Navigator) => {
      switch (route.key) {
        case "LISTING":
          return (
            <Listing
              key={route.key}
              navigator={navigator}
              title={route.title}
              refresh={refreshKey}
            />
          );
        case "LOGIN":
          return (
            <Login key={route.key} navigator={navigator} title={route.title} />
          );
        case "RULES":
          return (
            <Rules key={route.key} navigator={navigator} title={route.title} />
          );
        case "STEP": {
          return (
            <Step
              key={route.key}
              navigator={navigator}
              title={route.title}
              stepKey={route.stepKey}
              stepIndex={route.stepIndex}
            />
          );
        }
        default:
          return <pre>{JSON.stringify(route)}</pre>;
      }
    },
    [refreshKey]
  );
  return (
    <Ons.Navigator
      initialRouteStack={initialRouteStack()}
      animation="slide"
      onPostPop={(event: {
        navigator: Ons.Navigator;
        enterPage: { data?: { refresh?: boolean; navigator: Ons.Navigator } };
        routes: { routes: RouteKey[] };
      }) => {
        console.log(event);
        if (event.enterPage.data?.refresh) {
          setRefreshKey(Math.random());
          event.enterPage.data.refresh = false;
        }
      }}
      renderPage={renderPage}
    />
  );
}

export default App;
