import { memo, useEffect, useCallback, useState } from "react";
import * as Ons from "react-onsenui";
import { GoogleSpreadsheetRow } from "google-spreadsheet";
import { getSteps, loadRules, loadTeams } from "../../sheets";

const loadTeamsWrap = async (
  setTeams: (value: string[]) => unknown,
  setRules: (value: string[]) => unknown
) => {
  setTeams(await loadTeams());
  setRules(await loadRules());
};

const RulesImpl = ({
  navigator,
  title,
}: {
  navigator: Ons.Navigator;
  title: string;
}) => {
  const [team, setTeam] = useState<string | null>(
    window.localStorage.getItem("team")
  );
  const [teams, setTeams] = useState<string[] | null>(null);
  const [rules, setRules] = useState<string[] | null>(null);

  useEffect(() => {
    loadTeamsWrap(setTeams, setRules);
  }, [setTeams, setRules]);

  const selectTeam = useCallback(
    (team: string) => {
      window.localStorage.setItem("team", team);
      setTeam(team);
    },
    [setTeam]
  );

  const showClues = useCallback(() => {
    navigator.pushPage(
      {
        key: "LISTING",
        title: "Steps",
      },
      { animation: "slide" }
    );
  }, [navigator]);

  return (
    <Ons.Page>
      <Ons.Toolbar inline>
        <div className="left"></div>
        <div className="center">{title}</div>
        <div className="right"></div>
      </Ons.Toolbar>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img style={{ height: 280, maxWidth: "100vw" }} src="/logo.gif" />
      </div>
      <Ons.ListHeader>Rules</Ons.ListHeader>
      {(rules === null ? ["Fetching.."] : rules).map((rule, i) => (
        <Ons.ListItem
          key={i}
          style={{
            backgroundColor: "var(--list-background-color)",
          }}
        >
          <div
            style={{
              marginTop: 6,
              marginBottom: (rules?.length ?? 0) - 1 === i ? 20 : 6,
            }}
          >
            {rule}
          </div>
        </Ons.ListItem>
      ))}

      {teams != null ? (
        <>
          <Ons.ListHeader>Select a team</Ons.ListHeader>
          <Ons.List
            dataSource={teams}
            renderRow={(teamName: string, idx: number) => (
              <Ons.ListItem
                key={idx}
                modifier={idx === (teams?.length ?? 0) - 1 ? "longdivider" : ""}
                tappable
                onClick={() => selectTeam(teamName)}
              >
                <div className="center">{teamName}</div>
                <div className="right">
                  {team === teamName ? (
                    <Ons.Icon style={{ color: "#885094" }} icon="fa-check" />
                  ) : null}
                </div>
              </Ons.ListItem>
            )}
          />
        </>
      ) : (
        <div
          style={{
            width: "100%",
            marginTop: 25,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Ons.ProgressCircular indeterminate />
        </div>
      )}

      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "var(--list-background-color)",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 32,
          paddingBottom: 24,
        }}
      >
        <Ons.Button disabled={team === null} onClick={showClues}>
          Show the clues!
        </Ons.Button>
      </div>
    </Ons.Page>
  );
};

export const Rules = memo(RulesImpl);
